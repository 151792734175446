<template>
    <div class="aops-all-assets-table">
        <div class="aop-assets-title">筛选资产</div>
        <el-form
            ref="form"
            :inline="true"
            :model="form"
        >
            <el-form-item label="内部ID">
                <el-input
                    v-model="form.origin_id"
                    placeholder="输入编号检索"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="外部ID">
                <el-input
                    v-model="form.asset_uid"
                    placeholder="输入外部ID"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item>
                <span
                    class="aop-form-btn"
                    @click="handleSearch()"
                >查询</span>
                <span
                    class="aop-clear-btn aop-form-btn"
                    @click="handleClear()"
                >清空</span>
            </el-form-item>
        </el-form>
        <el-table
            ref="tableData"
            v-loading="tableLoading"
            header-cell-class-name="table-head aop-table-head"
            cell-class-name="table-cell"
            row-class-name="aop-table-row"
            :data="assetsData"
            row-key="asset_uid"
            style="width: 100%;"
            height="515px"
        >
            <el-table-column
                v-for="col in tableColumns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :width="col.width"
                :min-width="col.minWidth"
                :align="col.align"
            :fixed="col.fixed"
            >
                <template slot-scope="{ row }">
                    <div v-if="col.prop === 'add'">
                        <i
                            v-if="!row.isAdd"
                            class="iconfont cursor-pointer"
                            @click="handleAdd(row)"
                        >&#xe668;</i>
                        <span v-else-if="row.isAdd">已添加</span>
                    </div>
                    <div v-else-if="col.prop === 'name'">{{row.name}}</div>
                     <div v-else-if="col.prop == 'status'"> {{getStatus(row[col.prop])}}</div>
                    <div v-else-if="col.prop === 'preview'">
                        <img class="aop-preview-img" :src="`${row.preview}?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1`" alt=""  />
                    </div>
                    <div v-else>{{ row[col.prop] }}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            :current-page.sync="pageNumber"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
import api from '@/api/asset';
import { tableColumns } from './data';

export default {
    props: {
        // 表格loading
        tableLoading: { type: Boolean, default: false },
        // 表格数据
        assetsData: {
            type: Array,
            default: () => ([])
        },
        // 当前已选资产集合
        addAssetsData: {
            type: Array,
            default: () => ([])
        },
        maxQuantity: {
            type: Number || null,
            default: null
        },
        disabled: { // 禁用，不可添加/删除
            type: Boolean,
            default: false
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            form: { // 查询form
                origin_id: '',
                asset_uid: ''
            },
            tableColumns, // 表格的列
            // 分页数据
            pageSize: 10,
            pageNumber: 1,
            total: 0
        };
    },
    watch: {
        /**
         * 监听表格loading状态
         * @param {Boolean} newVal 当前loading状态
        */
        tableLoading(newVal) {
            if (newVal) {
                this.getList();
            }
        }
    },
    /**
     * mounted
    */
    mounted() {
        this.getList();
    },
    methods: {
        /**
         * 获取数据
        */
        async getList() {
            const params = {
                limit: this.pageSize,
                page: this.pageNumber,
                filter: {
                    ...this.form,
                    status: '5'
                },
                market: 'US'
            };
            const res = await api.recAssetFilterList(params);
            if (res.data.code === 10200) {
                this.total = res.data.data.total;
                this.handleEditChangeAdd(res.data.data.items);
            }
            this.$emit('update:tableLoading', false);
        },
        /**
         * 处理列表数据
         * @param {Array} data 列表数据
        */
        handleEditChangeAdd(data) {
            this.addAssetsData.forEach((ele) => {
                data.forEach((element) => {
                    if (element.asset_uid === ele.asset_uid) {
                        this.$set(element, 'isAdd', true);
                    }
                });
            });
            this.$emit('onChangeData', { assets: data });
        },
        /**
         * 点击查询按钮
        */
        handleSearch() {
            this.pageNumber = 1;
            this.$emit('update:tableLoading', true);
        },
        /**
         * 点击清空按钮
        */
        handleClear() {
            this.form.origin_id = '';
            this.form.asset_uid = '';
            this.$refs.form.resetFields();
        },
        /**
         * 添加单个资产
         * @param {string | Object} data 资产
        */
        handleAdd(data) {
            if (this.disabled) {
                this.$$warning('不可编辑');
                return;
            }
            if (this.maxQuantity && this.addAssetsData.length === this.maxQuantity) {
                this.$$warning('关联资产最多可添加100个');
                return;
            }
            const newCusData = [...this.assetsData];
            const newAddData = [...this.addAssetsData];
            newAddData.push(data);
            this.$set(data, 'isAdd', true);
            this.$emit('onChangeData', { assets: newCusData, addAssets: newAddData });
        },
        /**
         * 改变分页数
         * @param {number} val 分页数
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNumber = 1;
            this.$emit('update:tableLoading', true);
        },
        /**
         * 改变页码
         * @param {number} val 页码
        */
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$emit('update:tableLoading', true);
        },
        /**
         * 资产状态
         * @param {*} status 状态
           @return{*}
         */
        getStatus(status) {
            switch (status) {
                case 1:
                    return '未核验';
                case 2:
                    return '已核验';
                case 3:
                    return '未审核';
                case 4:
                    return '已审核';
                case 5:
                    return '已发布';
                case 6:
                    return '重制中';
                case 999:
                    return '已作废';
                case 998:
                    return '待提交';
                default:
                    break;
            }
        }
    }
};
</script>
<style lang="less">
@import "./table.less";
</style>
