const columns = [{
    prop: 'preview',
    label: '缩略图片',
    width: 120,
    align: 'left'
}, {
    prop: 'origin_id',
    label: '内部ID',
    width: 130,
    align: 'left',
    minWidth: '20%'
}, {
    prop: 'asset_uid',
    label: '外部ID',
    align: 'left',
    minWidth: '25%',
    width: 130
}, {
    prop: 'name',
    label: '资产名称',
    align: 'left',
    minWidth: '25%',
    width: 130
}, {
    prop: 'status',
    label: '资产状态',
    align: 'left',
    width: 120
}];
export const tableColumns = [
    ...columns,
    {
        prop: 'add',
        label: '添加',
        align: 'center',
        minWidth: '110px',
        fixed: 'right'
    }
];
export const selectColumns = [{
    prop: 'sort',
    label: '拖拽排序',
    width: 120,
    align: 'left',
    minWidth: '8%'
},
...columns,
{
    prop: 'delete',
    label: '删除',
    align: 'center',
    minWidth: '100px',
    fixed: 'right'
}];
