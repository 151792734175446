<template>
  <div v-if="hasResource('asset_group_edit')" class="aops-add-property-assets">
     <div class="aop-back" @click="back">
        <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
    </div>
      <el-form
        ref="form"
        :model="form"
        label-width="90px"
        label-position="left"
        :rules="rules">
        <div class="aop-property-title">{{id ? '编辑':'新增' }}组合资产</div>
        <el-form-item class="aop-property-item" prop="group_name_cn"  label="组合名称">
            <!-- <span class="aop-item-label"> 组合名称</span> -->
           <el-input v-model="form.group_name_cn" placeholder="请输入组合名称"/>
        </el-form-item>
        <!-- <el-form-item class="aop-property-item" prop="group_name">
            <span class="aop-item-label">组合名称(英文)</span>
           <el-input v-model="form.group_name" placeholder="请输入英文名称"/>
        </el-form-item> -->
        <el-form-item class="aop-property-item" label="组合状态" required>
            <!-- <span class="aop-radio-label">组合状态</span> -->
            <el-radio
                v-for="item in status"
                :key="item.value"
                v-model="form.status"
                :label="item.value"
                >{{ item.name }}</el-radio>
        </el-form-item>
        <selectAssets :select-assets.sync="selectAssets" :max-quantity="100"/>
      </el-form>
    <div class="aop-property-btn" >
        <el-button v-loading="btnLoading" class="black-btn aop-btn-size" @click="confirmAdd">确 认</el-button>
        <el-button class="white-btn aop-btn-size" @click="back">取 消</el-button>
      </div>
  </div>
</template>
<script>
import api from '../../../../api/assets';
import { addRules, status } from './data';
import selectAssets from '@/pages/_common/selectAssets/selectAssets';

export default {
    components: { selectAssets },

    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            dialogVisible: false,
            id: '',
            status,
            form: {
                group_name_cn: '',
                group_name: '',
                status: '2'
            },
            rules: addRules,
            btnLoading: false,
            selectAssets: [] // 已选资产
        };
    },
    /**
    * 初始化
    */
    created() {
        this.init();
    },
    methods: {
        /**
        * 根据id查询数据
        */
        init() {
            const id = this.$route.query.id || '';
            this.id = id;
            if (id) {
                api.assetPropertyInfo(id).then((resp) => {
                    if (resp.data.code === 10200) {
                        this.form.status = resp.data.data.status;
                        this.form.group_name = resp.data.data.group_name;
                        this.form.group_name_cn = resp.data.data.group_name_cn;
                        this.selectAssets = resp.data.data.asset_list;
                    } else {
                        this.$$error(resp.data.message);
                    }
                    this.btnLoading = false;
                });
            }
        },
        /**
        * 确认提交
        */
        confirmAdd() {
            if (!this.selectAssets.length) {
                this.$$error('请添加资产');
                return;
            }
            if (this.btnLoading) {
                return;
            }
            const id = this.$route.query.id || '';
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    const data = {
                        group_id: id || '',
                        asset_uids: this.selectAssets.map((item) => item.asset_uid),
                        ...this.form
                    };
                    api.editAssetProperty(data).then((resp) => {
                        if (resp.data.code === 10200) {
                            this.$$success('组合资产保存成功!');
                            this.back();
                        } else {
                            this.$$error(resp.data.message);
                        }
                        this.btnLoading = false;
                    });
                }
            });
        },
        /**
        * 取消
        */
        back() {
            this.form = {
                group_name_cn: '',
                group_name: '',
                status: 2
            };
            this.selectAssets = [];
            this.$router.push('/assets/property');
        }
    }
};
</script>

<style lang="less">
@import "./add.less";
</style>
