<template>
    <!-- 选择资产 -->
    <div class="aops-select-assets">
        <div class="aop-select-assets-body">
            <assetsTable
                :table-loading.sync="tableLoading"
                :assets-data="assetsData"
                :add-assets-data="selectAssets"
                :max-quantity="maxQuantity"
                :disabled="disabled"
                @onChangeData="changeData"
            />
            <div class="aop-assets-right sortList">
                <div class="aop-assets-title">添加资产 {{ selectAssets.length }} </div>
                <div class="aop-explain">点击右下角确认按钮，将视为成功添加。</div>
                <el-table
                    ref="dragTable"
                    header-cell-class-name="table-head aop-table-head"
                    cell-class-name="table-cell"
                    row-class-name="aop-table-row"
                    :data="selectAssets"
                    row-key="asset_uid"
                    height="560px"
                    style="width: 100%;"
                >
                    <el-table-column
                        v-for="col in selectColumns"
                        :key="col.prop"
                        :prop="col.prop"
                        :label="col.label"
                        :width="col.width"
                        :min-width="col.minWidth"
                        :fixed="col.fixed"
                        :align="col.align"
                    >
                        <template slot-scope="{ row }">
                            <div v-if="col.prop === 'delete'" @click="handleDel(row)">
                                <i class="iconfont cursor-pointer">&#xe678;</i>
                            </div>
                            <div v-else-if="col.prop =='sort'">
                                <i class="iconfont">&#xe663;</i>
                            </div>
                            <div v-else-if="col.prop == 'name'" class="assets-name">{{row.name}}</div>
                            <div v-else-if="col.prop == 'status'"> {{getStatus(row[col.prop])}}</div>
                            <div v-else-if="col.prop == 'preview'">
                                <img
                                    class="aop-preview-img"
                                    :src="`${row.preview}?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1`" alt="" />
                            </div>
                            <div v-else>{{ row[col.prop] }}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';
import assetsTable from './table.vue';
import { selectColumns } from './data';

export default {
    components: { assetsTable },
    props: {
        selectAssets: {
            type: Array,
            default: () => []
        },
        maxQuantity: {
            type: Number || null,
            default: null
        },
        disabled: { // 禁用，不可添加/删除
            type: Boolean,
            default: false
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            tableLoading: true, // 左侧表格loading，控制是否重新调取接口获取左侧列表数据
            assetsData: [], // 左侧数据集合
            selectColumns, // 已选表格的列
            pageNumber: 1,
            pageSize: 10
        };
    },
    /**
     * mounted
     */
    mounted() {
        this.tableRowDrop();
    },
    methods: {
        /**
         * 点击删除单个
         * @param {string | object} data 单个资产
        */
        handleDel(data) {
            if (this.disabled) {
                this.$$warning('不可编辑');
                return;
            }
            // 删除单个
            const newSelectAssets = [...this.selectAssets];
            const index = newSelectAssets.findIndex((item) => item.asset_uid === data.asset_uid);
            newSelectAssets.splice(index, 1);
            this.$emit('update:selectAssets', newSelectAssets);
            this.assetsData.forEach((element) => {
                if (element.asset_uid == data.asset_uid) {
                    element.isAdd = false;
                    this.$set(element, 'isAdd', false);
                }
            });
        },
        /**
         * 获取列表数据，点击全部添加，添加单个，触发此方法
         * 更改列表数据，已选列表数据
         * @param {Object} data {assets, addAssets}
        */
        changeData({ assets, addAssets }) {
            if (assets) {
                this.assetsData = assets;
            }
            if (addAssets) {
                this.$emit('update:selectAssets', addAssets);
            }
        },
        /**
         * 表格拖拽排序
         */
        tableRowDrop() {
            // 表格中需要实现行拖动，选中tr父级元素
            const tbody = document.querySelector(
                '.sortList .el-table__body-wrapper tbody'
            );
            const _that = this;
            Sortable.create(tbody, {
                /**
                 * onEnd
                 */
                onEnd({ newIndex, oldIndex }) {
                    if (this.disabled) {
                        return;
                    }
                    const currRow = _that.selectAssets.splice(oldIndex, 1)[0];
                    _that.selectAssets.splice(newIndex, 0, currRow);
                }
            });
        },
        /**
         * 资产状态
         * @param {*} status 状态
           @return{*}
         */
        getStatus(status) {
            switch (status) {
                case 1:
                    return '未核验';
                case 2:
                    return '已核验';
                case 3:
                    return '未审核';
                case 4:
                    return '已审核';
                case 5:
                    return '已发布';
                case 6:
                    return '重制中';
                case 999:
                    return '已作废';
                case 998:
                    return '待提交';
                default:
                    break;
            }
        }
    }
};
</script>
<style lang="less">
@import "./selectAssets.less";
</style>
