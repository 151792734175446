export const addRules = {
    // group_name: [
    //     { required: true, message: '请输入英文名称', trigger: 'blur' },
    //     { min: 0, max: 20, message: '最多输入20个字符', trigger: 'blur' }
    // ],
    group_name_cn: [
        { required: true, message: '请输入名称', trigger: 'blur' },
        { min: 0, max: 20, message: '最多输入20个字符', trigger: 'blur' }
    ]
};

export const status = [
    {
        value: '2',
        name: '启用'
    }, {
        value: '1',
        name: '停用'
    }
];
